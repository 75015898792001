import React, { useState } from 'react';
import { useDatabase, useDatabaseObjectData } from 'reactfire';
import { Box, Button } from '@chakra-ui/core';
import Post from './Post';
import PostForm from './PostForm';

function Announcements(props) {
	const database = useDatabase();
	const messageRef = database.ref(props.type);
	const allMessages = useDatabaseObjectData(messageRef);

	const [ addPost, setPost ] = useState(false);

	const handleClick = () => {
		setPost(!addPost);
	};

	const removePost = (postID) => {
		console.log('removing Post:', postID);
		messageRef.child(postID).remove();
		return true;
	};

	const addReply = (postID, postData) => {
		console.log('replying');
		console.log(postID);
		console.log(postData);
		const newReply = messageRef.child(postID).child('replies').push();
		newReply.set(postData);
	};

	const removeReply = (postID, replyID) => {
		console.log('removing Post:', postID);
		messageRef.child(postID).child('replies').child(replyID).remove();
		return true;
	};

	return (
		<Box>
			<Box
				maxW={props.type === 'messages' ? '600px' : '90vw'}
				d="flex"
				flexWrap="wrap"
				justifyContent="space-around"
				mx="auto"
				my={3}
				className="Announcements"
			>
				{Object.keys(allMessages)
					.sort((a, b) => (a > b ? -1 : 1))
					.map((k) => (
						<Post
							key={k}
							removePost={() => removePost(k)}
							postID={k}
							data={allMessages[k]}
							type={props.type}
							isAdmin={props.isAdmin}
							addReply={(postData) => addReply(k, postData)}
							removeReply={removeReply}
						/>
					))}{' '}
			</Box>

			<Box d="flex" justifyContent="center">
				<Button my={3} onClick={handleClick}>
					Add Topic
				</Button>
			</Box>
			{addPost && <PostForm type={props.type} closeForm={handleClick} />}
		</Box>
	);
}

export default Announcements;
