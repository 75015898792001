import React from 'react';
import { Box, Heading, Flex, Text, Button } from '@chakra-ui/core';
import { useAuth } from 'reactfire';
import { NavLink } from 'react-router-dom';
import './Heading.css';

const signOut = (auth) => auth.signOut().then(() => console.log('signed out'));

const MenuItems = ({ children }) => (
	<Text mt={{ base: 4, md: 0 }} mr={6} display="block">
		{children}
	</Text>
);

const Header = (props) => {
	const [ show, setShow ] = React.useState(false);
	const handleToggle = () => setShow(!show);
	const auth = useAuth();
	return (
		<Flex
			as="nav"
			align="center"
			justify="space-between"
			wrap="wrap"
			padding="1rem"
			bg="blue.800"
			color="gray.100"
			{...props}
		>
			<Flex align="center" mr={5}>
				<Heading as="h1" size="lg">
					<NavLink to="/">Boucher Place</NavLink>
				</Heading>
			</Flex>

			<Box display={{ xs: 'block', md: 'none' }} onClick={handleToggle}>
				<svg fill="white" width="12px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
					<title>Menu</title>
					<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
				</svg>
			</Box>

			<Box
				display={{ xs: show ? 'block' : 'none', md: 'flex' }}
				width={{ xs: 'full', md: 'auto' }}
				alignItems="center"
				flexGrow={1}
			>
				<MenuItems>
					<NavLink exact activeClassName="active-link" to="/announcements">
						Announcements
					</NavLink>
				</MenuItems>
				<MenuItems>
					<NavLink exact activeClassName="active-link" to="/vendors">
						Vendors
					</NavLink>
				</MenuItems>
				<MenuItems>
					<NavLink exact activeClassName="active-link" to="/information">
						Information
					</NavLink>
				</MenuItems>
				{props.isAdmin && (
					<MenuItems>
						<NavLink exact activeClassName="active-link" to="/admin">
							Admin
						</NavLink>
					</MenuItems>
				)}
			</Box>

			<Box display={{ xs: show ? 'block' : 'none', md: 'block' }} mt={{ base: 4, md: 0 }}>
				<Button
					onClick={() => {
						signOut(auth);
					}}
					bg="transparent"
					border="1px"
				>
					Sign Out
				</Button>
			</Box>
		</Flex>
	);
};

export default Header;
