import { Box, Button, Input, Text } from '@chakra-ui/core';
import React, { useState } from 'react';
import { useDatabaseObjectData, useDatabase, useUser } from 'reactfire';
import { FormControl, FormLabel } from '@chakra-ui/core';

export default function HouseForm(props) {
	const user = useUser();
	const [ profileData, setProfileData ] = useState({
		house: ''
	});

	const [ isSubmitted, setSubmitted ] = useState(false);

	const handleChange = (e) => {
		setProfileData({
			...profileData,
			[e.target.name]: e.target.value
		});
	};

	const database = useDatabase();
	const ref = database.ref(`users/${user.uid}`);
	const profile = useDatabaseObjectData(ref);

	const addNewEmail = (event) => {
		event.preventDefault();
		return ref.update(profileData, (error) => {
			if (error) {
				console.log(error);
			} else {
				setSubmitted(true);
			}
		});
	};

	return (
		<Box bg="white" w="90%" m={5} p={5} color="black" rounded="md" mx="auto">
			{profile.house ? (
				<Box my={3} d="flex" justifyContent="center">
					<Text maxW="500px" textAlign="center">
						Thank you for signing up. Your account will be reviewed and verified as a Boucher Place resident
						within 24 hours.
					</Text>
				</Box>
			) : (
				<Box>
					<FormControl isRequired py={2}>
						<FormLabel htmlFor="house">House Number</FormLabel>
						<Input
							name="house"
							onChange={handleChange}
							type="number"
							id="house"
							aria-describedby="House Number"
						/>
					</FormControl>
					<Box d="flex" justifyContent="center">
						<Button onClick={addNewEmail}>{isSubmitted ? 'Thank You' : 'Submit'}</Button>
					</Box>
				</Box>
			)}
		</Box>
	);
}
