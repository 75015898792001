import { Box, Button, Input } from '@chakra-ui/core';
import React, { useState } from 'react';
import { SuspenseWithPerf, useDatabase, useUser } from 'reactfire';
import { FormControl, FormLabel, Textarea, Icon, Text } from '@chakra-ui/core';
import './PostForm.css';

export default function PostForm(props) {
	const user = useUser();
	const [ postData, setPostData ] = useState({
		title: '',
		desc: '',
		url: '',
		rating: ''
	});

	const [ isSubmitting, setSubmitting ] = useState(false);
	const [ isSubmitted, setSubmitted ] = useState(false);

	const handleChange = (e) => {
		setPostData({
			...postData,
			[e.target.name]: e.target.value
		});
	};

	const database = useDatabase();
	const ref = database.ref(props.type);
	const addNewPost = (event) => {
		event.preventDefault();
		setSubmitting(true);
		const newPostRef = ref.push();
		return newPostRef.set({ ...postData, time: Date.now(), author: user.displayName }, (error) => {
			setSubmitting(false);
			if (error) {
				console.log(error);
			} else {
				setSubmitted(true);
				props.closeForm();
			}
		});
	};

	return (
		<Box bg="gray.200" w="90%" my={1} p={5} color="black" rounded="md" mx="auto">
			<SuspenseWithPerf fallback={'Loading...'}>
				<form onSubmit={addNewPost}>
					<FormControl isRequired py={2}>
						<FormLabel htmlFor="title">Title</FormLabel>
						<Input
							name="title"
							onChange={handleChange}
							type="text"
							id="title"
							aria-describedby="Post title"
						/>
					</FormControl>
					<FormControl isRequired py={2}>
						<FormLabel htmlFor="desc">Description</FormLabel>
						<Textarea name="desc" onChange={handleChange} id="desc" aria-describedby="description" />
					</FormControl>
					{props.type === 'vendors' && (
						<Box d="flex" mt="2" alignItems="baseline">
							<FormLabel mb="4" mr={3}>
								Rating:{' '}
							</FormLabel>
							{Array(5).fill('').map((_, i) => (
								<Icon
									className="star-input"
									name="star"
									size="1.5em"
									mb={2}
									key={i}
									onClick={() => {
										setPostData({
											...postData,
											rating: i + 1
										});
									}}
									color={i < postData.rating ? 'teal.500' : 'gray.300'}
								/>
							))}
						</Box>
					)}
					<FormControl py={2}>
						<FormLabel htmlFor="url">Link URL (Optional):</FormLabel>
						<Input name="url" onChange={handleChange} type="text" id="url" aria-describedby="Link URL" />
					</FormControl>
					<FormControl py={2}>
						<FormLabel htmlFor="image">Image URL (Optional):</FormLabel>
						<Input
							name="image"
							onChange={handleChange}
							type="text"
							id="image"
							aria-describedby="Image URL"
						/>
					</FormControl>

					<Box d="flex" justifyContent="center">
						<Button
							mt={4}
							isLoading={isSubmitting}
							type="submit"
							isDisabled={isSubmitted}
							color="white"
							bg="blue.800"
						>
							{isSubmitted ? 'Thank You!' : 'Submit'}
						</Button>
					</Box>
				</form>
			</SuspenseWithPerf>
		</Box>
	);
}
