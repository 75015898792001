import './Loading.css'
import React from 'react';
import {Spinner, Box} from '@chakra-ui/core';



function Loading() {
	return (
		<Box d='flex' alignItems='center' justifyContent='center' className="Loading-Box">
			<Spinner size='xl' color='white' className="Spinner"/>
		</Box>
	);
}

export default Loading;
