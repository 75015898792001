import React, { useState } from 'react';
import { Box, Button, Image, Badge, Icon, Link, Flex } from '@chakra-ui/core';
import Reply from './Reply';

//determine if it gets a new tag!
const isNew = (time) => {
	const cutoff = 48;
	const elapsedHours = (Date.now() - time) / (1000 * 60 * 60);
	return elapsedHours < cutoff;
};

function Post(props) {
	const { data } = props;
	const [ isReplying, setReplying ] = useState(false);

	const handleReplyClick = () => {
		setReplying(!isReplying);
	};

	return (
		<Box m="2" width={props.type === 'messages' ? '' : '500px'} borderWidth="1px" rounded="lg" overflow="auto">
			{data.image && <Image boxSize="100px" src={data.image} alt="announcement image" objectFit="fill" />}
			<Box p="6">
				<Box d="flex" alignItems="baseline">
					{isNew(data.time) && (
						<Badge mr="2" rounded="full" px="2" variantColor="teal">
							New
						</Badge>
					)}
					<Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" isTruncated>
						{data.title}
					</Box>
					{props.isAdmin && (
						<Button variantColor="red" size="xs" ml="3" onClick={props.removePost}>
							<i className="fas fa-trash-alt" />
						</Button>
					)}
				</Box>
				<Box>
					<Box d="flex" alignItems="baseline">
						<Box
							color="gray.500"
							fontWeight="semibold"
							letterSpacing="wide"
							fontSize="xs"
							textTransform="uppercase"
							ml="0"
							mr="3"
						>
							{data.author}
						</Box>
						{data.rating && (
							<Box d="flex" mt="2" alignItems="center">
								{Array(5)
									.fill('')
									.map((_, i) => (
										<Icon name="star" key={i} color={i < data.rating ? 'teal.500' : 'gray.300'} />
									))}
							</Box>
						)}
					</Box>
				</Box>
				<Box mt="1" fontWeight="200" as="p" lineHeight="tight">
					{data.desc}
				</Box>
				{data.url && (
					<Flex mt="2" justifyContent="center">
						<Link wordBreak="break-all" color="blue.800" href={data.url} alt="link">
							{data.url}
						</Link>
					</Flex>
				)}
				{data.replies &&
					Object.keys(data.replies).map((k) => (
						<Box key={k} ml={3} mt={4}>
							<Box d="flex" alignItems="baseline">
								<Box
									color="gray.500"
									fontWeight="semibold"
									letterSpacing="wide"
									fontSize="xs"
									textTransform="uppercase"
									mr="3"
								>
									{data.replies[k].author}
								</Box>

								{data.replies[k].rating && (
									<Box d="flex" mt="2" alignItems="center">
										{Array(5)
											.fill('')
											.map((_, i) => (
												<Icon
													name="star"
													key={i}
													color={i < data.replies[k].rating ? 'teal.500' : 'gray.300'}
												/>
											))}
									</Box>
								)}
							</Box>

							<Box mt="1" fontWeight="200" as="p" lineHeight="tight">
								{props.isAdmin && (
									<Button
										variantColor="red"
										size="xs"
										mr="3"
										onClick={() => {
											props.removeReply(props.postID, k);
										}}
									>
										<i className="fas fa-trash-alt" />
									</Button>
								)}
								{data.replies[k].reply}
							</Box>
						</Box>
					))}

				<Box d="flex" justifyContent="center">
					{isReplying ? (
						<Reply addReply={props.addReply} type={props.type} closeForm={handleReplyClick} />
					) : (
						<Button onClick={handleReplyClick} mt={3}>
							Reply
						</Button>
					)}
				</Box>
			</Box>
		</Box>
	);
}

export default Post;
