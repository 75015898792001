import React from 'react';
import { useUser, useDatabase, useDatabaseObjectData } from 'reactfire';

function isEmpty(obj) {
	return Object.keys(obj).length === 0;
}

const getProfile = ({ uid, email, emailVerified, photoURL, metadata, displayName }) => ({
	uid,
	email,
	emailVerified,
	photoURL,
	metadata,
	displayName
});

function SaveUser() {
	const user = useUser();
	const database = useDatabase();
	const userRef = database.ref('users');
	const userInfo = useDatabaseObjectData(userRef.child(user.uid));

	if (isEmpty(userInfo)) {
		const profileRef = database.ref(`users/${user.uid}`);
		profileRef.set(getProfile(user));
	}

	return <div className="SaveUser" />;
}

export default SaveUser;
