import { Box, Button, Input } from '@chakra-ui/core';
import React, { useState } from 'react';
import { SuspenseWithPerf, useDatabase } from 'reactfire';
import { FormControl, FormLabel } from '@chakra-ui/core';

export default function EmailForm(props) {
	const [ profileData, setProfileData ] = useState({
		firstName: '',
		lastName: '',
		email: '',
		house: ''
	});

	const [ isSubmitting, setSubmitting ] = useState(false);
	const [ isSubmitted, setSubmitted ] = useState(false);

	const handleChange = (e) => {
		setProfileData({
			...profileData,
			[e.target.name]: e.target.value
		});
	};

	const database = useDatabase();
	const ref = database.ref('emailList');
	const addNewEmail = (event) => {
		event.preventDefault();
		setSubmitting(true);
		const newEmailRef = ref.push();
		return newEmailRef.set(profileData, (error) => {
			setSubmitting(false);
			if (error) {
				console.log(error);
			} else {
				setSubmitted(true);
				setTimeout(() => {
					props.closeForm();
				}, 2000);
			}
		});
	};

	return (
		<Box bg="white" w="90%" p={5} color="black" rounded="md" mx="auto">
			<SuspenseWithPerf fallback={'Loading...'}>
				<form onSubmit={addNewEmail}>
					<FormControl isRequired py={2}>
						<FormLabel htmlFor="firstName">First name</FormLabel>
						<Input
							name="firstName"
							onChange={handleChange}
							type="text"
							id="firstName"
							aria-describedby="First Name Input"
						/>
					</FormControl>
					<FormControl isRequired py={2}>
						<FormLabel htmlFor="lastName">Last Name</FormLabel>
						<Input
							name="lastName"
							onChange={handleChange}
							type="text"
							id="lastName"
							aria-describedby="Last Name Input"
						/>
					</FormControl>
					<FormControl isRequired py={2}>
						<FormLabel htmlFor="house">House Number</FormLabel>
						<Input
							name="house"
							onChange={handleChange}
							type="text"
							id="house"
							aria-describedby="House Number"
						/>
					</FormControl>

					<FormControl isRequired py={2}>
						<FormLabel htmlFor="email">Email address</FormLabel>
						<Input
							name="email"
							onChange={handleChange}
							type="email"
							id="email"
							aria-describedby="email-helper-text"
						/>
					</FormControl>
					<Box d="flex" justifyContent="center">
						<Button
							mt={4}
							isLoading={isSubmitting}
							type="submit"
							isDisabled={isSubmitted}
							color="white"
							bg="blue.800"
						>
							{isSubmitted ? 'Thank You!' : 'Submit'}
						</Button>
					</Box>
				</form>
			</SuspenseWithPerf>
		</Box>
	);
}
