import React from 'react';
import { Box, Text, Flex, Heading } from '@chakra-ui/core';
import Jumbotron from './Jumbotron';
import { Link } from 'react-router-dom';
import InformationBox from './InformationBox';

const information = {
	camllc: {
		title: 'CAM LLC',
		subtitle: 'Community Association Management',
		link: 'http://camllc.com',
		contacts: [
			{
				name: 'Joy Boone',
				phone: '(410) 602-6898 x110',
				email: 'jboone@camllc.com'
			}
		],
		address: [ 'Community Association Management LLC', 'PO Box 579', 'Stevenson, MD 21153' ],
		note:
			'Quarterly HOA dues can be paid online using the camllc website. Call if you need assistance finding your account number'
	},

	boucherUtility: {
		title: 'Boucher Place Utility',
		subtitle: 'Annual Utility Bill Payment Collector',
		address: [ 'Boucher Place Utility LLC', '2071 Chain Bridge Road', 'Suite 510', 'Vienna, VA 22182' ],
		contacts: [
			{
				phone: '(703) 849-8700',
				email: 'accountspayable@basheerandedgemoore.com',
				name: 'Shweta Pradhan'
			}
		]
	},

	googleDrive: {
		title: 'Boucher Residents Google Drive',
		subtitle: 'Use this link to access community documents including bylaws',
		link: 'https://drive.google.com/drive/folders/1MXYjwsv63ejdzVos_K2h1cDISMLqBlGC?usp=sharing'
	}
};

export default function Information() {
	return (
		<Box>
			<Jumbotron
				title="Boucher Place Community Information"
				url="https://images.unsplash.com/photo-1603625094339-c6d5f80e9cfa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2732&q=80"
			/>
			<Flex justify="space-around" alignItems="flex-start" mx="20" flexWrap="wrap">
				{Object.keys(information).map((k) => <InformationBox data={information[k]} />)}
			</Flex>
		</Box>
	);
}
