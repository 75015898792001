import { Box, Button, Input } from '@chakra-ui/core';
import React, { useState } from 'react';
import { SuspenseWithPerf, useUser } from 'reactfire';
import { FormControl, FormLabel, Icon } from '@chakra-ui/core';

export default function Reply(props) {
	const user = useUser();
	const [ postData, setPostData ] = useState({
		reply: '',
		author: user.displayName,
		authorID: user.uid,
		rating: ''
	});

	const handleChange = (e) => {
		setPostData({
			...postData,
			[e.target.name]: e.target.value
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		props.addReply(postData);
		props.closeForm();
	};

	return (
		<Box bg="gray.200" w="90%" p={5} color="black" rounded="md" my={2} mx="auto">
			<SuspenseWithPerf fallback={'Loading...'}>
				<form onSubmit={handleSubmit}>
					<FormControl isRequired py={2}>
						<FormLabel htmlFor="reply">Reply</FormLabel>
						<Input name="reply" onChange={handleChange} type="text" id="reply" aria-describedby="Reply" />
					</FormControl>
					{props.type === 'vendors' && (
						<Box d="flex" mt="2" alignItems="baseline">
							<FormLabel mb="4" mr={3}>
								Rating:{' '}
							</FormLabel>
							{Array(5).fill('').map((_, i) => (
								<Icon
									className="star-input"
									name="star"
									size="1.5em"
									mb={2}
									key={i}
									onClick={() => {
										setPostData({
											...postData,
											rating: i + 1
										});
									}}
									color={i < postData.rating ? 'teal.500' : 'gray.300'}
								/>
							))}
						</Box>
					)}
					<Box d="flex" justifyContent="center">
						<Button dmt={4} type="submit" color="white" bg="blue.800">
							Submit Reply
						</Button>
					</Box>
				</form>
			</SuspenseWithPerf>
		</Box>
	);
}
