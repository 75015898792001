import React from 'react';
import { useDatabase, useDatabaseObjectData } from 'reactfire';
import { Box, Heading, Checkbox } from '@chakra-ui/core';
import './admin.css';

function Admin() {
	const database = useDatabase();
	const userRef = database.ref('users');
	const allUsers = useDatabaseObjectData(userRef);

	const residentRef = database.ref('residents');
	const residentUsers = useDatabaseObjectData(residentRef);

	const adminRef = database.ref('admins');
	const adminUsers = useDatabaseObjectData(adminRef);

	const handleChange = (uid, event) => {
		const { name, checked } = event.target;
		if (name === 'residents') {
			residentRef.update({ [uid]: checked });
		}
		if (name === 'admins') {
			adminRef.update({ [uid]: checked });
		}
	};

	return (
		<Box className="Admin">
			<Heading m={2} textAlign="center">
				Administrator
			</Heading>
			<table className="admin-table">
				<thead>
					<tr>
						<th>Name</th>
						<th>Email</th>
						<th>House #</th>
						<th>Resident</th>
						<th>Admin</th>
					</tr>
				</thead>
				<tbody>
					{Object.keys(allUsers).map((k) => {
						const uid = allUsers[k].uid;
						return (
							<tr key={uid} className="table-row">
								<td>{allUsers[k].displayName}</td>
								<td>{allUsers[k].email}</td>
								<td>{allUsers[k].house || '-'}</td>
								<td>
									<Checkbox
										isChecked={residentUsers[uid] ? true : false}
										name="residents"
										variantColor="green"
										uid={uid}
										onChange={(e) => {
											handleChange(uid, e);
										}}
									/>
								</td>
								<td>
									<Checkbox
										isChecked={adminUsers[uid] ? true : false}
										name="admins"
										variantColor="red"
										uid={uid}
										onChange={(e) => {
											handleChange(uid, e);
										}}
									/>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</Box>
	);
}

export default Admin;
