import React from 'react';
import { Box, Text, Flex, Heading } from '@chakra-ui/core';

export default function InformationBox({ data }) {
	return (
		<Box maxW='400px' m='3' border="2px" p='4' borderRadius="md" borderColor="blue.800">
			<Heading textAlign="center" size="md">
				{data.title}
			</Heading>
			<Text mt="1" textAlign="center" my="2" fontSize="lg">
				{data.subtitle}
			</Text>
			<Text mt="1"  wordBreak='break-word'  fontWeight="600">
				<a target="_blank"  href={data.link}>
          {data.link}
				</a>
			</Text>
			{data.contacts &&
				data.contacts.map((c) => (
					<Box my="3">
						<Text mt="1" fontWeight="600">
							Name: {c.name}
						</Text>
						<Text mt="1" fontWeight="600">
							Email: {c.email}
						</Text>
						<Text mt="1" fontWeight="600">
							Phone: {c.phone}
						</Text>
					</Box>
				))}
			{data.address &&
				data.address.map((l) => (
					<Text textAlign="center" mt="0">
						{l}
					</Text>
				))}
			<Text mt="4">{data.note}</Text>
		</Box>
	);
}
