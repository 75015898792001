import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Loading from './Loading'
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, CSSReset} from '@chakra-ui/core';
import { FirebaseAppProvider, SuspenseWithPerf } from 'reactfire';
import {BrowserRouter} from 'react-router-dom';

const firebaseConfig = {
	/* Paste your config object from Firebase console here */
	apiKey: 'AIzaSyDXTwzU9Y7Vc2KveotCGURuxzGeQIFbVfM',
	authDomain: 'boucherpl-12715.firebaseapp.com',
	databaseURL: 'https://boucherpl-12715.firebaseio.com',
	projectId: 'boucherpl-12715',
	storageBucket: 'boucherpl-12715.appspot.com',
	messagingSenderId: '688067282710',
	appId: '1:688067282710:web:e4e1b8e032a25b44d3f237',
	measurementId: 'G-GLW31767FN'
};

ReactDOM.render(
	<React.StrictMode>
		<FirebaseAppProvider firebaseConfig={firebaseConfig}>
			<ThemeProvider>
				<CSSReset />
				<SuspenseWithPerf fallback={<Loading/>}>
				<BrowserRouter>
				<App />
				</BrowserRouter>
				</SuspenseWithPerf>
			</ThemeProvider>
		</FirebaseAppProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
