import React from 'react';
import { Heading, Flex } from '@chakra-ui/core';

export default function Jumbotron(props) {
	const { title, url } = props;
	const bgString = `url(${url})`;
	const background = `no-repeat ${bgString} 50% 50%`;
	return (
		<Flex w="100%" m="3" justifyContent="center">
			<Flex
				w="90%"
				minH="150px"
				borderRadius="md"
				boxShadow="md"
				align="center"
				justify="center"
				background={background}
				bgSize="cover"
			>
				<Heading
					size="2xl"
					color="white"
					letterSpacing=".1em"
					fontWeight="400"
					textShadow="1px 1px 3px black"
					p={4}
				>
					{title}
				</Heading>
			</Flex>
		</Flex>
	);
}
