import './App.css';
import Home from './Home';
import React from 'react';
import Heading from './Heading';
import SaveUser from './SaveUser';
import { useUser, useDatabase, useDatabaseObjectData } from 'reactfire';
import { Route, Switch, Redirect } from 'react-router-dom';
import Announcements from './Announcements';
import Admin from './Admin';
import HouseForm from './HouseForm';
import Information from './Information';
import { Box } from '@chakra-ui/core';

function App() {
	const user = useUser();
	const database = useDatabase();
	console.log(user);
	const residentRef = database.ref('residents');
	const residentUsers = useDatabaseObjectData(residentRef);
	const isResident = user ? residentUsers[user.uid] : false;

	const adminRef = database.ref('admins');
	const adminUsers = useDatabaseObjectData(adminRef);
	const isAdmin = user ? adminUsers[user.uid] : false;

	return (
		<div className="App">
			{user !== null && <Heading isAdmin={isAdmin} />}
			{/* //save the user information if they are logged in */}
			{user !== null && <SaveUser />}
			{user && !isResident && <HouseForm />}
			<Switch>
				<Route exact component={Home} path="/" />

				<Route
					exact
					render={() =>
						isResident ? <Announcements isAdmin={isAdmin} type="messages" /> : <Redirect to="/" />}
					path="/announcements"
				/>
				<Route
					exact
					render={() =>
						isResident ? (
							<Box>
								<Information />
								<Announcements isAdmin={isAdmin} type="information" />
							</Box>
						) : (
							<Redirect to="/" />
						)}
					path="/information"
				/>

				<Route exact render={() => (isAdmin ? <Admin /> : <Redirect to="/" />)} path="/admin" />
				<Route
					exact
					render={() =>
						isResident ? <Announcements isAdmin={isAdmin} type="vendors" /> : <Redirect to="/" />}
					path="/vendors"
				/>
			</Switch>
		</div>
	);
}

export default App;
