import React, { useState, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/core';
import './Home.css';
import EmailForm from './EmailForm';
import { SuspenseWithPerf } from 'reactfire';
import AuthButton from './AuthButton';

export default function Home() {
	useEffect(() => {
		document.querySelector('#Home').classList.add('colorize');
	}, []);

	const [ formState, setFormState ] = useState(false);

	const handleclick = () => {
		setFormState(!formState);
	};

	return (
		<Box id="Home" className="Home">
			{formState ? (
				<Box className="email-form">
					<SuspenseWithPerf fallback={'Loading...'}>
						<EmailForm closeForm={handleclick} />
					</SuspenseWithPerf>
				</Box>
			) : (
				<div className="text-box">
					<Text className="heading-primary">Boucher Place</Text>
					<Text className="heading-secondary">Annapolis, MD</Text>
					{/* <Button onClick={handleclick} size="lg" leftIcon="email" color="white" bg="blue.800">
						Join our email list
					</Button> */}
					<AuthButton></AuthButton>
				</div>
			)}
			<Box d="flex" justifyContent="center" alignItems="center" className="f-icon" w="100%">
				<a href="https://www.facebook.com/groups/773009929542726/" target="_blank" rel="noreferrer">
					<Box rounded="md" padding={1} mb={5}>
						<i style={{ color: 'white' }} className="fab fa-2x fa-facebook-square" />
					</Box>
				</a>
			</Box>
		</Box>
	);
}
